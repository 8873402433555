import "../scss/app.scss";
// import "../index.html";
import { $ } from './nanojs.js';
import Lethargy from "lethargy"


document.addEventListener("DOMContentLoaded", function(event) {
    $(".scroll").on("click", function(event) {
        event.preventDefault();
        let target = this.getAttribute("href");
        let element = document.querySelectorAll(target)[0];
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    });
});

const lethargy = new Lethargy.Lethargy();
const page = document.getElementById("page");
let currentScrollY = page.scrollTop;
let isResizing = null;
let isScrolling = null;
let vh = window.innerHeight;

function resetMobileChrome() {
    let elems = document.getElementsByClassName("chrome-reset");
    // for (let i=0; i<elems.length; i++) {
    //     elems[i].classList.add("url-bar-height");
    // }
    setTimeout(() => {
        for (let i = 0; i < elems.length; i++) {
            // elems[i].classList.remove("url-bar-height");
        }
    }, 10000);
}

function setVH(skipAnimate) {
    vh = window.innerHeight;
    // console.log("vh height", vh, "page height", page.offsetHeight);
    // On Android Chrome tablets, the window innerHeight gets computed as smaller than the
    // actual page height. I will now take a moment to complain.
    // WTF why would you do this are you serious whyyyyyy
    // I will now offer a fix. If the page height is smaller, unset the vars to fallback
    // to vh units
    // if (vh < page.offsetHeight) {
    //     vh = page.offsetHeight;
    // }

    // if (vh >= page.offsetHeight) {
        document.documentElement.style.setProperty('--vh-full', `${vh}px`);
        document.documentElement.style.setProperty('--vh', `${vh * 0.01}px`);
    // } else {
        // Tablet behavior
        // document.documentElement.style.removeProperty('--vh-full');
        // document.documentElement.style.removeProperty('--vh');
    // }
    // Update: adding the bottom url-bar-height box at the bottom of the page container seems to have
    // helped? Also, part of the issue was scrolling during the webpack load stage in testing?
    if (skipAnimate) {
        document.documentElement.style.setProperty('--vh-time', `.0s`);
    } else {
        document.documentElement.style.setProperty('--vh-time', `.3s`);
    }
}
setVH();
// resetMobileChrome();

// Having to do this really sucks
// Use homebrewed vh and also fix resize scroll related things on Android Chrome and similar behaving
// browsers
function resize() {
    window.clearTimeout(isResizing);
    isResizing = setTimeout(function () {   // Should only get called on resizes with no scrolling
        setVH();
        console.log("no scroll happened on resize")
        isResizing = null;
    }, 66);
    if (isScrolling === null) {     // Resize with no scroll
        console.log("resize with no scroll");
        setVH(true);
        // Should actually get size of the elements rather than rely on About Me being section 4
        roundScroll();
    }
}
window.addEventListener("resize", resize);

let aboutMeAnimated = false;

function scroll(e) {
    currentScrollY = page.scrollTop;

    window.clearTimeout(isScrolling);
    isScrolling = setTimeout(function () {
        if (!aboutMeAnimated) {
            if (Math.round(currentScrollY / page.offsetHeight) >= 3) {
                aboutMeAnimated = true;
                animateAboutMe();
            }
        }
        isScrolling = null;
    }, 66);

    if (isResizing) {
        let scrollType = lethargy.check(e)
        console.log('scroll was part of resize')

        window.clearTimeout(isResizing);
        isResizing = setTimeout(function () {
            if (scrollType === false) {
                roundScroll();
            }
            console.log("resize scroll complete");
            setVH();
            isResizing = null;
        }, 66);
    }
}

page.addEventListener("scroll", scroll);


function roundScroll() {
    let scrollDiff = currentScrollY % vh;
    console.log(scrollDiff, currentScrollY, vh);
    if (scrollDiff <= vh/2) {
        console.log("option1")
        page.scrollTo({behavior: "smooth", block: "start", inline: "nearest", top: currentScrollY - scrollDiff});
    } else {
        console.log("option2", currentScrollY - scrollDiff, vh)
        page.scrollTo({behavior: "smooth", block: "start", inline: "nearest", top: currentScrollY - scrollDiff + vh});
        setTimeout(function () {
            console.log(page.scrollTop);
        }, 500);
    }
}

function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
}
getScrollbarWidth();

function animateAboutMe(cancelAnimation) {
    let elems = document.getElementsByClassName("ab-info");
    for (let i=0; i<elems.length; i++) {
        elems[i].classList.add("show");
        if (cancelAnimation) {
            elems[i].classList.add("no-anim");
            setTimeout(function () {
                elems[i].classList.remove("no-anim");
            }, 100);
        }
    }
}

if (Math.round(currentScrollY / page.offsetHeight) >= 3) {
    animateAboutMe(true);
    aboutMeAnimated = true;
}

function cycleBackward(string) {
    let newString = "";
    for (let i=0; i<string.length; i++) {
        newString = newString + String.fromCharCode(string.charCodeAt(i) - 1);
    }
    return newString;
}

function cycleForward(string) {
    let newString = "";
    for (let i=0; i<string.length; i++) {
        newString = newString + String.fromCharCode(string.charCodeAt(i) + 1);
    }
    return newString;
}


function replaceMail() {
    let elems = document.getElementsByClassName("dmail");
    let email = cycleForward("odsdq?dmb`un-mds");
    for (let i=0; i<elems.length; i++) {
        elems[i].href = `mailto:${email}`;
        elems[i].innerHTML = email;
    }
}
replaceMail();
